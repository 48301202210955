import { render, staticRenderFns } from "./transferredModal.vue?vue&type=template&id=46b9dbf8&scoped=true&"
import script from "./transferredModal.vue?vue&type=script&lang=js&"
export * from "./transferredModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46b9dbf8",
  null
  
)

export default component.exports