<template>
  <div>
    <div v-for="(formedForm, index)  in formedFormList" v-bind:key="index">
    <el-form
      
      
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="Дата" prop="date">
            <el-date-picker
             @change="setDatastored(formedForm)"
              value-format="yyyy-MM-dd"
              v-model="formedForm.date"
              type="date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Количество" prop="store">
            <el-input v-model="formedForm.store"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="Единица измерения">
            <el-input v-model="waste.pss93UnitInfo.name" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Удалить">
            <el-button @click="dropLine(index)" type="primary" style="float: left" 
              >Удалить</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      
      
    </el-form>
    </div>
    <el-row>
        <el-col :span="24">
          <el-button @click="addLine" type="primary" style="float: left" 
            >Добавить</el-button>
        </el-col>
    </el-row>
    <br>
    <br>
    <el-row>
      <el-col :span="24">
        <el-button type="primary" @click="save" :disabled="block"
          >Сохранить</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "formedModal",
  props: ["waste"],
  data() {
    return {
      userId:Cookies.get('userId') ,
      block: false,
      actionId: 1,
      datastored:null,
      formedFormList: [{
        amount: this.waste.amount,
        store: "",
        date: "",
      }],
      formedFormAdd: {
        amount: this.waste.amount,
        store: "",
        date: "",
      },
      rules: {
        store: [
          { required: true, message: "Введите количество", trigger: "blur" },
        ],

        date: [{ required: true, message: "Введите дату", trigger: "blur" }],
      },
    };
  },

  methods: {
    setDatastored(formedForm){
      this.getDatastored(formedForm).then((response) => {
        if(response.data == true)
        {this.block=true;
          console.log(this);
          this.message("Внимание! Цепочка устарела!", "error", true);
          return;
        }
        if(response.data == false)
        {this.block=true;
          console.log(this);
          this.message("Внимание! Цепочка не начала действовать!", "error", true);
          return;
        }
        this.block=false;
        this.datastored = response.data;
       
      });
    },
    async getDatastored(formedForm){
      let data = {date:"",techProcessHasWasteId:""};
      data.date=formedForm.date;
      data.techProcessHasWasteId = this.waste.techProcessHasWasteId;
      return await this.$store.dispatch(
                "getDataStored",
                data
              );
      },
    
    addLine(){
      console.log(this);
      this.formedFormList.push({
        amount: this.waste.amount,
        store: "",
        date: "",
      });
    },
    dropLine(index)
    {
      this.formedFormList.splice(index, 1);
    },
    validat(){
      let i = 0;
      let date;
      this.formedFormList.forEach((item) =>{
        if (item.block == true)
          i= 3;
        if(item.date == "" || item.store== "")
        //{this.ErrorMasseg("Внимание! Заполните всте поля");
          i= 1;
        if((new Date(item.date.split('-')[0],item.date.split('-')[1]-1,item.date.split('-')[2])<new Date(new Date().getFullYear(),0,1) && (this.userId!=409   && this.userId!=265 && this.userId!=266 && this.userId!=267
&& this.userId!=268 && this.userId!=269 && this.userId!=270 && this.userId!=271
&& this.userId!=272 && this.userId!=273 && this.userId!=274 && this.userId!=275
&& this.userId!=276 && this.userId!=277 && this.userId!=278 && this.userId!=279
&& this.userId!=280 && this.userId!=281 && this.userId!=282 && this.userId!=283
&& this.userId!=284 && this.userId!=285 && this.userId!=286 && this.userId!=287
&& this.userId!=288 && this.userId!=289 && this.userId!=290  && this.userId!=233 && this.userId!=234  && this.userId!=292 && this.userId!=245 && this.userId!=14)))
        //{this.ErrorMasseg("Внимание! Дата одного из значений меньше чем "+new Date().getFullYear()+" год");
          i= 2; date=item.date;
      })
      return {id:i,date:date};
    },
    
    save() {
      switch (this.validat().id)
      {
        case 1: 
        this.ErrorMasseg("Внимание! Заполните все поля");
        return;
        case 2: 
        this.ErrorMasseg("Внимание! Внесение данных за "+new Date(this.validat().date).getFullYear()+" год ограничено. Закрыт отчетный период")
        return;
        case 3:
        this.ErrorMasseg("Проверьте дату в блоке(ах)");
        return;
        case 0: break;
      }
      /*this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.formedForm.amount = this.waste.amount;
          /*console.log("VALID", this.formedForm);*/
          this.$store
            .dispatch("receiveDataEntryForm", {
              modalData: this.formedFormList,
              id: this.$route.params.processId,
              actionId: this.actionId,
            })
            .then(() => {
              this.$store.dispatch(
                "getMainPageForDataEntry",
                this.$route.params.processId
              );
              
              this.$store.dispatch("setShowModal", null);
              this.$store.dispatch("setShowModal", false);
            });
        /*}
      });*/
    },
    ErrorMasseg(Error){
      this.message(Error, "error", true);
    },
  },
};
</script>

<style scoped></style>
